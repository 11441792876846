/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.css';

@import '~ngx-toastr/toastr';

* {
  font-family: 'Noto Sans JP',
    sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.login-page,
.register-page,
.change-password-page,
.email-confirm-page {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.navbar-color {
  background-color: #FFFFFF;
  border-bottom: 1px solid #B5B5B6;
}

.company-page {
  background-color: white;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding-top: 100px;
}

.header-bottom {
  margin-top: 10px;
  border-bottom: 1px solid #3e3e3e;
  padding-bottom: 10px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700 !important;
  font-size: 28px;
  color: #3e3e3e;

  @media (max-width: 576px) {
    font-size: 24px;
  }
}

.radio-top {
  @media (max-width: 1358px) {
    margin-top: 10px;
  }
}

.left-border {
  border-left: 4px solid #b5b5b6;
  padding-left: 10px;
}

.main-padding {
  padding: 40px 30px;
}

.main-top {
  margin-top: 25px;
}

.step-left {
  margin-left: 5px;
}

input[type='radio'] {
  width: 28px;
  height: 28px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #b5b5b6 !important;
}

.custom-control-input-danger.custom-control-input-outline:checked[type='radio']
  ~ .custom-control-label::after {
  background-image: url("assets/icons/dot.svg") !important;
}

.custom-control-label::before {
  width: 28px;
  height: 28px;
  top: -0.06rem;
  left: -1.9rem;
}

.custom-control-label::after {
  top: 0.1rem;
  left: -1.76rem;
  width: 23px;
  height: 23px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.padd-right {
  padding-right: 15px;
}

.padd-left {
  padding-left: 15px;
}

.btn-width {
  max-width: 250px;
  width: 100%;
  padding: 4% 10% !important;
  padding-top: 20px !important;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.form-control {
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #b5b5b6;
  opacity: 1;
}
.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control-input-outline ~ .custom-control-label::before {
  background-color: transparent !important;
  box-shadow: none;
  border: 2px solid #b5b5b6 !important;
}
.request-title {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500 !important;
  font-size: 20px;
  color: #3e3e3e;
}
.btn-primary {
  color: #E10800;
  background-color: #F2F2F2;
  border: 1px solid #E10800;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  padding-left: 10% !important;
}
@media (min-width: 1800px) {
  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    padding-left: 14% !important;
  }
}
.btn-back {
  background: #444444 0% 0% no-repeat padding-box;
  color: #ffffff !important;
}
.btn-next{
  background: #F2F2F2;
  border: 1px solid #E10800;
  color: #E10800;
  font-weight: 700;
}
.btn-next, .btn-back {
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  line-height: 1.33;
  text-align: center;
  opacity: 1;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #E10800;
  cursor: pointer;  
  width: 250px;
  height: 70px;
  border-radius: 35px;
  font-size: 20px;
  padding: 0;
}
.btn-next:hover {
  text-decoration: none !important;
  color: rgba(255, 255, 255, 0.9);
  background: #E10800;
}
.two-btn {
  border-collapse: separate;
  border-spacing: 15px;
}

.btn-pink{
  width: 250px;
  height: 70px;
  border-radius: 35px;
  font-size: 20px;
  padding: 0; 
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  line-height: 1.33;
  text-align: center;
  background: #F2F2F2;
  border: 1px solid #E10800;
  color: #E10800;
  opacity: 1;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500 !important;
  letter-spacing: 0px;
  font-weight: 700;
  opacity: 1;
  cursor: pointer;
}
.btn-pink:hover {
  text-decoration: none !important;
  color: rgba(255, 255, 255, 0.9);
  background: #E10800;
}
// Columns 2021-08-11 Detials
.col-dest{
  width: 266px !important;
  max-width: 266px !important;
  min-width: 266px !important;
}
.col-dest1{
  min-width: 600px;
  flex: 1 1;
}
.col-dest2{
  min-width: 227px;
  flex: 1 1;
}
.detail{
  min-width: 866px;
}
.detail1{
  min-width: 493px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E10800;
  border-radius: 10px;
}
.form-hd-1{
  min-width: 62rem;
}
.form-hd-2{
  min-width: 40rem;
}
.form-wd-1{
  min-width: 60rem;
}
.form-wd-2{
  min-width: 35rem;
}